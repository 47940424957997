<main>
  <div class="body-content-centered">
    <nav class="body-content-header-nav">
      <div class="body-content-header-nav-title">Benutzerrolle {{ roleId ? 'bearbeiten' : 'erstellen' }}</div>
    </nav>

    <div id="form">
      <div id="loading" *ngIf="isLoading || isLoadingPermissions; else content">
        <mat-spinner color="accent" diameter="32"></mat-spinner>
      </div>

      <ng-template #content>
        <div id="content">
          <mat-form-field appearance="fill">
            <mat-label>Bezeichnung</mat-label>
            <input matInput [(ngModel)]="roleName" required>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Beschreibung</mat-label>
            <textarea matInput [(ngModel)]="roleDescription"></textarea>
          </mat-form-field>

          <h2>Berechtigungen</h2>
          <ul id="reminders">
            <li *ngFor="let p of permissions">
              <mat-checkbox [(ngModel)]="p.isChecked" [title]="p.description">{{ p.label }}</mat-checkbox>
            </li>
          </ul>

          <app-action-button [disabled]="isSaving || roleName == ''" iconKey="done" (click)="onSaveClick()">
            {{ roleId ? 'Speichern' : 'Erstellen' }}
          </app-action-button>
        </div>
      </ng-template>
    </div>
  </div>
</main>
