<main>
  <div class="body-content-centered">
    <div class="loading" *ngIf="isLoading; else content">
      <mat-spinner color="accent" diameter="32"></mat-spinner>
    </div>

    <ng-template #content>
      <nav class="body-content-header-nav">
        <div class="body-content-header-nav-title">Benutzerrollen</div>
        <div class="body-content-header-nav-actions">
          <app-action-button iconKey="add" routerLink="/home/settings/roles/create" *ngIf="isEventManager">
            Benutzerrolle erstellen
          </app-action-button>
        </div>
      </nav>

      <ul class="list">
        <li *ngFor="let role of roles" [routerLink]="'/home/settings/roles/' + role.key">
          <div class="details">
            <div class="title">{{ role.name }}</div>
            <div class="permission-count">{{ role.permissionKeys.length }} Berechtigungen</div>
          </div>
        </li>
      </ul>

      <div *ngIf="roles.length <= 0">
        Keine Rollen vorhanden.
      </div>
    </ng-template>
  </div>
</main>
