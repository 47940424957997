<main>
  <div class="body-content-centered">
    <nav class="body-content-header-nav">
      <div class="body-content-header-nav-title">Leitstelle Tirol</div>
    </nav>

    <div>
      Neue Einsätze werden automatisch über die App an deine Mitglieder ausgesendet.<br>Deine Ressourcen (Fahrzeuge,
      Schleifen, ...) werden ebenso automatisch mit der Anbindung abgeglichen.<br><br>
    </div>

    <div class="loading" *ngIf="isLoading; else content">
      <mat-spinner color="accent" diameter="32"></mat-spinner>
    </div>

    <ng-template #content>
      <div id="content">
        <div id="login" *ngIf="!info?.is_active">
          <mat-form-field appearance="fill" color="accent">
            <mat-label>Benutzername</mat-label>
            <input matInput type="text" placeholder="" [(ngModel)]="username" required>
          </mat-form-field>
          <mat-form-field appearance="fill" color="accent">
            <mat-label>Passwort</mat-label>
            <input matInput type="password" placeholder="" [(ngModel)]="password" required>
          </mat-form-field>
        </div>

        <div id="status" *ngIf="info?.is_active">
          <span></span><b>Aktiv</b>
        </div>

        <div id="actions" *ngIf="info != null">
          <app-action-button *ngIf="!info?.is_active" (click)="enableBinding()">
            Aktivieren
          </app-action-button>
          <app-action-button *ngIf="info?.is_active" (click)="disableBinding()">
            Anbindung deaktivieren
          </app-action-button>
        </div>

        <div id="alarm-groups" *ngIf="(info?.alarm_groups?.length ?? 0) > 0">
          <h2>Schleifen</h2>
          <ul>
            <li *ngFor="let ag of info!.alarm_groups" (click)="onLstAlarmGroupClick($event, ag.id)">
              <div class="title">{{ ag.name }}</div>
              <div class="details">
                Empfänger: {{ mapUserSelectionTypeToSting(ag.user_selection_type) }}<br>
                Benachrichtigung: {{ mapPushTypeToString(ag.push_type) }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </ng-template>
  </div>
</main>
