import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {OrganizationService} from "../../core/services/organization.service";
import {Observable} from "rxjs";
import {UserOrganization} from "../../core/models/UserOrganization";
import {OrganizationSwitcherDialogResult} from "../organization-switcher-dialog/organization-switcher-dialog.component";
import {OrganizationGroup} from "../../core/network/models/OrganizationGroup";
import {Api} from "../../core/network/Api";
import {HttpClient} from "@angular/common/http";
import {LstAlarmGroup} from "../../core/models/LstAlarmGroup";
import {BindingsLsTirolAlarmGroupsIdRequest} from "../../core/network/request/BindingsLsTirolAlarmGroupsIdRequest";

@Component({
  selector: 'app-edit-ls-tirol-alarm-group-dialog',
  templateUrl: './edit-ls-tirol-alarm-group-dialog.component.html',
  styleUrls: ['./edit-ls-tirol-alarm-group-dialog.component.scss']
})
export class EditLsTirolAlarmGroupDialogComponent implements OnInit {

  isLoadingLstAlarmGroup = false;
  isLoadingGroups = false;
  isSaving = false;

  userSelectionTypeCode: string = 'no-recipients';
  organizationGroupId: string = '';
  pushTypeCode: string | null = null;

  lstAlarmGroup: LstAlarmGroup | null = null;

  organizationGroups: OrganizationGroup[] = [];

  selectedOrganizationId: number | null = null;

  constructor(private dialog: MatDialog, public dialogRef: MatDialogRef<EditLsTirolAlarmGroupDialogComponent>,
              private organizationService: OrganizationService, private httpClient: HttpClient,
              @Inject(MAT_DIALOG_DATA) public data: EditLsTirolAlarmGroupDialogData | null) {
  }

  ngOnInit(): void {
    this.selectedOrganizationId = this.organizationService.selectedOrganizationId;

    this.loadAlarmGroup();
    this.loadGroups();
  }

  onCloseClick(event: any): void {
    this.dialogRef.close();
  }

  onOrganizationClick(event: MouseEvent, organizationId: number): void {
    if (organizationId === this.selectedOrganizationId) {
      this.dialogRef.close();
      return;
    }

    this.organizationService.selectedOrganizationId = organizationId;
    localStorage.setItem('organizationId', String(this.organizationService.selectedOrganizationId));

    this.dialogRef.close({
      dataChanged: true
    } as OrganizationSwitcherDialogResult);
  }

  onSaveClick(event: MouseEvent): void {

    let organizationGroupId: string | null = this.organizationGroupId;
    if (organizationGroupId == "") organizationGroupId = null;

    if (this.userSelectionTypeCode == "group" && !organizationGroupId) {
      alert("Keine Gruppe ausgewählt! Bitte wähle eine Gruppe und versuche es nochmal.");
      return;
    }

    let pushTypeCode: string | null = this.pushTypeCode;
    if (pushTypeCode == "") pushTypeCode = "none";

    this.isSaving = true;

    let request = {
      userSelectionTypeCode: this.userSelectionTypeCode,
      organizationGroupId: organizationGroupId,
      pushTypeCode: pushTypeCode
    } as BindingsLsTirolAlarmGroupsIdRequest;

    Api.postBindingsLsTirolAlarmGroupsId(this.httpClient, `${this.selectedOrganizationId}`, this.data!.id, request, () => {
      this.dialogRef.close();
    }, error => {
      // todo
    }, () => {
      this.isSaving = false;
    })
  }

  public static open(dialog: MatDialog, data: EditLsTirolAlarmGroupDialogData): Observable<any> {
    return dialog.open(EditLsTirolAlarmGroupDialogComponent, {
      maxWidth: 600,
      panelClass: 'sp-dialog-panel',
      disableClose: false,
      data: data
    }).afterClosed()
  }

  private loadAlarmGroup(): void {
    this.isLoadingLstAlarmGroup = true;

    Api.bindingsLsTirolAlarmGroupsId(this.httpClient, `${this.selectedOrganizationId}`, this.data!.id, response => {
      this.lstAlarmGroup = response.lstAlarmGroup;

      this.userSelectionTypeCode = this.lstAlarmGroup.user_selection_type;
      this.organizationGroupId = this.lstAlarmGroup.organizationGroupId ?? '';
      this.pushTypeCode = this.lstAlarmGroup.push_type ?? 'none';

      this.onUserSelectionTypeChange();
    }, error => {
      // todo
    }, () => {
      this.isLoadingLstAlarmGroup = false;
    })
  }

  private loadGroups(): void {
    let organizationId = `${this.selectedOrganizationId}`;

    this.isLoadingGroups = true;

    Api.organizationGroups(this.httpClient, organizationId, response => {
      this.organizationGroups = response.groups;
      this.onUserSelectionTypeChange();
    }, error => {
      // todo: handle error
    }, () => {
      this.isLoadingGroups = false;
    })
  }

  public getOrganizations(): UserOrganization[] {
    return this.organizationService.organizations.sort((a, b) => a.name > b.name ? 1 : -1);
  }

  public mapOrganizationTypeToName(organizationTypeId: number) {
    switch (organizationTypeId) {
      case 4:
        return "Unternehmen";
      case 7:
        return "Privatperson";
    }
    return "";
  }

  onUserSelectionTypeChange(event: any | null = null) {
    if (this.userSelectionTypeCode != "group") {
      this.organizationGroupId = "";
    }

    if (this.userSelectionTypeCode == "no-recipients") {
      this.pushTypeCode = "none";
    }
  }
}

export interface EditLsTirolAlarmGroupDialogData {
  id: string;
}
