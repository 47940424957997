<main class="sp-dialog">
  <header>
    <div class="header-icon">
      <mat-icon>group</mat-icon>
    </div>
    <div class="header-title">Schleife bearbeiten</div>
    <div class="header-actions">
      <mat-icon matRipple (click)="onCloseClick($event)">close</mat-icon>
    </div>
  </header>
  <div class="content">
    <div class="sp-dialog-loading" *ngIf="isLoadingLstAlarmGroup || isLoadingGroups || isSaving">
      <mat-spinner diameter="32" strokeWidth="3"></mat-spinner>
    </div>

    <ng-container>
      <div><b>Bezeichnung:</b> {{ lstAlarmGroup?.name }}</div>
      <br>

      <mat-form-field appearance="fill">
        <mat-label>Empfängerauswahl</mat-label>
        <mat-select [(ngModel)]="userSelectionTypeCode" (selectionChange)="onUserSelectionTypeChange($event)">
          <mat-option [value]="'no-recipients'">Keine Empfänger</mat-option>
          <mat-option [value]="'all'">Sammelruf</mat-option>
          <mat-option [value]="'group'">Gruppe</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Gruppe</mat-label>
        <mat-select [(ngModel)]="organizationGroupId" [disabled]="userSelectionTypeCode != 'group'"
                    [required]="userSelectionTypeCode == 'group'">
          <mat-option [value]="og.id" *ngFor="let og of organizationGroups">{{ og.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Benachrichtigung</mat-label>
        <mat-select [(ngModel)]="pushTypeCode" [disabled]="userSelectionTypeCode == 'no-recipients'">
          <mat-option [value]="'critical'">Laut</mat-option>
          <mat-option [value]="'normal'">Normal</mat-option>
          <mat-option [value]="'none'">Deaktiviert</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <footer>
      <button class="btn-action btn-primary" (click)="onSaveClick($event)"
              [disabled]="isLoadingLstAlarmGroup || isLoadingGroups || isSaving">Speichern
      </button>
    </footer>
  </div>
</main>
