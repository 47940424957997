import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {OrganizationService} from "../../../../core/services/organization.service";
import {Router} from "@angular/router";
import {ToolbarService} from "../../../../core/services/toolbar.service";
import {Api} from "../../../../core/network/Api";
import {BindingsExternalICalendarsResponse} from "../../../../core/network/response/BindingsExternalICalendarsResponse";
import {ExternalICalendar} from "../../../../core/network/models/ExternalICalendar";
import {MatDialog} from "@angular/material/dialog";
import {
  ExternalIcalendarEditDialogComponent, ExternalIcalendarEditDialogData
} from "../../../../dialogs/external-icalendar-edit-dialog/external-icalendar-edit-dialog.component";

@Component({
  selector: 'app-binding-external-icalendar',
  templateUrl: './binding-external-icalendar.component.html',
  styleUrls: ['./binding-external-icalendar.component.scss']
})
export class BindingExternalIcalendarComponent implements OnInit, OnDestroy {
  isLoading = false;
  isSaving = false;

  calendarName = '';
  calendarUrl = '';
  calendarIsEnabled = true;

  externalICalendars: ExternalICalendar[] = [];

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService, private router: Router,
              private toolbarService: ToolbarService, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.toolbarService.setHierarchy([
      {title: "Konfiguration", path: "/home/settings"},
      {title: "Anbindungen", path: "/home/settings/bindings"}
    ]);
    this.toolbarService.setPageTitle("Externe Kalender");

    this.loadData();
  }

  ngOnDestroy() {
    this.toolbarService.resetHierarchy();
  }

  private loadData() {
    this.isLoading = true;
    Api.bindingsExternalICalendars(this.httpClient, {organization_id: `${this.organizationService.selectedOrganizationId}`}, (response: BindingsExternalICalendarsResponse) => {
      this.externalICalendars = response.external_icalendars;

      this.externalICalendars.forEach(x => {
        x.categories = x.categories.sort((a, b) => a > b ? 1 : -1);
      });

      // todo: remove
      //this.onItemEditClick({}, this.externalICalendars[1]);
    }, (error: string) => {
      // todo: handle error
    }, () => {
      this.isLoading = false;
    });
  }

  addCalendar(): void {
    if (this.calendarName.trim() === '') {
      alert('Leerer Name! Bitte überprüfe deine Eingaben und versuche es nochmal.');
      return;
    }
    if (this.calendarUrl.trim() === '') {
      alert('Leere Webadresse! Bitte überprüfe deine Eingaben und versuche es nochmal.');
      return;
    }

    this.isSaving = true;
    Api.bindingsExternalICalendarsAdd(this.httpClient, {
      organization_id: `${this.organizationService.selectedOrganizationId}`,
      name: this.calendarName,
      url: this.calendarUrl,
      isEnabled: this.calendarIsEnabled
    }, () => {
      this.calendarName = '';
      this.calendarUrl = '';
      this.calendarIsEnabled = true;
      this.loadData();
    }, (error: any) => {
      console.log(error);
      switch (error) {
        case 'invalid-url':
          alert('Die Webadresse entpricht nicht dem iCal-Format oder ist derzeit nicht verfügbar. Bitte überprüfe deine Eingaben und versuche es nochmal.');
          break;
        case 'already-exists':
          alert('Dieser Kalender wird bereits synchronisiert. Bitte überprüfe deine Eingaben und versuche es nochmal.');
          break;
        case 'not-licensed':
          alert("Die Anbindung von externen Kalendern ist in deinem Plan nicht verfügbar. Ändere deinen Plan und \"Abrechnung\".")
          break;
        default:
          alert("Unbekannter Fehler. Bitte versuche es später nochmal.");
      }
    }, () => {
      this.isSaving = false;
    });
  }

  navigateTo(route: string) {
    this.router.navigateByUrl(route);
  }


  onItemEditClick(event: any, item: ExternalICalendar): void {
    this.dialog.open(ExternalIcalendarEditDialogComponent, {
      maxWidth: 500,
      data: {
        item: item
      } as ExternalIcalendarEditDialogData,
      panelClass: 'sp-dialog-panel',
      disableClose: true
    }).afterClosed().subscribe(() => {
      // todo: if (afterClosed) afterClosed();
      this.loadData();
    });
  }

}
