import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {OrganizationService} from "../../core/services/organization.service";
import {UserOrganization} from "../../core/models/UserOrganization";

@Component({
  selector: 'app-organization-switcher-dialog',
  templateUrl: './organization-switcher-dialog.component.html',
  styleUrls: ['./organization-switcher-dialog.component.scss']
})
export class OrganizationSwitcherDialogComponent implements OnInit {

  selectedOrganizationId: number | null = null;

  constructor(private dialog: MatDialog, public dialogRef: MatDialogRef<OrganizationSwitcherDialogComponent>, private organizationService: OrganizationService) {
  }

  ngOnInit(): void {
    this.selectedOrganizationId = this.organizationService.selectedOrganizationId;
  }

  onOrganizationClick(event: MouseEvent, organizationId: number): void {
    if (organizationId === this.selectedOrganizationId) {
      this.dialogRef.close();
      return;
    }

    this.organizationService.selectedOrganizationId = organizationId;
    localStorage.setItem('organizationId', String(this.organizationService.selectedOrganizationId));

    this.dialogRef.close({
      dataChanged: true
    } as OrganizationSwitcherDialogResult);
  }

  public static open(dialog: MatDialog): Observable<any> {
    return dialog.open(OrganizationSwitcherDialogComponent, {
      maxWidth: 600,
      panelClass: 'sp-dialog-panel',
      disableClose: false
    }).afterClosed()
  }

  public getOrganizations(): UserOrganization[] {
    return this.organizationService.organizations.sort((a, b) => a.name > b.name ? 1 : -1);
  }

  public mapOrganizationTypeToName(organizationTypeId: number) {
    switch (organizationTypeId) {
      case 4:
        return "Unternehmen";
      case 7:
        return "Privatperson";
    }
    return "";
  }
}

export interface OrganizationSwitcherDialogResult {
  dataChanged: boolean;
}
