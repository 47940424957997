import {Component, Input, OnInit} from '@angular/core';
import {WaterSource} from "../../../core/network/models/WaterSource";

@Component({
  selector: 'app-wasserkarte-map-marker',
  templateUrl: './wasserkarte-map-marker.component.html',
  styleUrls: ['./wasserkarte-map-marker.component.scss']
})
export class WasserkarteMapMarkerComponent implements OnInit {

  @Input() operationWaterSource: WaterSource | null = null;

  constructor() {
  }

  ngOnInit(): void {
  }

  onLinkClick(event: MouseEvent): void {
    window.open(this.operationWaterSource?.wasserkartePortalUrl ?? '', '_blank')?.focus();
  }
}
