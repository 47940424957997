<main>
  <div class="body-content-centered">
    <nav class="body-content-header-nav">
      <div class="body-content-header-nav-title">Konfiguration</div>
    </nav>

    <ul>
      <li [routerLink]="'/home/settings/roles'" *ngIf="false">
        <div class="icon">
          <mat-icon>manage_accounts</mat-icon>
        </div>
        <div class="text">Benutzerrollen</div>
      </li>
      <li [routerLink]="'/home/settings/monitors'">
        <div class="icon">
          <mat-icon>tv</mat-icon>
        </div>
        <div class="text">Monitore</div>
      </li>
      <li [routerLink]="'/home/settings/bindings'">
        <div class="icon">
          <mat-icon>link</mat-icon>
        </div>
        <div class="text">Anbindungen</div>
      </li>
      <li [routerLink]="'/home/settings/organization/update'">
        <div class="icon">
          <mat-icon>edit</mat-icon>
        </div>
        <div class="text">Daten aktualisieren</div>
      </li>
      <li [routerLink]="'/home/settings/privacy'">
        <div class="icon">
          <mat-icon>lock</mat-icon>
        </div>
        <div class="text">Datenschutz</div>
      </li>
      <li [routerLink]="'/home/settings/billing'">
        <div class="icon">
          <mat-icon>payments</mat-icon>
        </div>
        <div class="text">Abrechnung</div>
      </li>
    </ul>
  </div>
</main>
