<main>
  <div class="body-content-centered">
    <nav class="body-content-header-nav">
      <div class="body-content-header-nav-title">wasserkarte.info</div>
    </nav>

    <div>
      <p>Sofort die nächsten Wasserentnahmestellen in der Umgebung des Einsatzortes in der App und im Dashboard
        abrufen.</p>
      <p><a href="https://wasserkarte.info" target="_blank">Website von wasserkarte.info</a> (Wasserkarte.info GmbH)</p>
    </div>

    <mat-expansion-panel id="privacy-info">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>shield</mat-icon>
          Datenschutz
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>Es werden an wasserkarte.info ausschließlich die Koordinaten des Einsatzortes übermittelt.</p>
    </mat-expansion-panel>

    <div class="loading" *ngIf="isLoading; else content">
      <mat-spinner color="accent" diameter="32"></mat-spinner>
    </div>

    <ng-template #content>
      <div id="content">
        <div id="login" *ngIf="!info?.is_active">
          <div>Erstelle einen neuen Zugriffsschlüssel für SmartPager im <a
            href="https://portal.wasserkarte.info/apis/tokens/view" target="_blank">wasserkarte.info Portal</a> und füge
            ihn hier ein.
          </div>
          <br>

          <mat-form-field appearance="fill" color="accent">
            <mat-label>Zugriffsschlüssel</mat-label>
            <input matInput type="text" placeholder="" [(ngModel)]="token" [disabled]="isProcessing" required>
          </mat-form-field>
        </div>

        <div id="status" *ngIf="info?.is_active">
          <span></span><b>Aktiv</b>
        </div>

        <div id="actions" *ngIf="info != null">
          <app-action-button *ngIf="!info?.is_active" (click)="enableBinding()" [disabled]="isProcessing">
            Aktivieren
          </app-action-button>
          <app-action-button *ngIf="info?.is_active" (click)="disableBinding()" [disabled]="isProcessing">
            Anbindung deaktivieren
          </app-action-button>
        </div>
      </div>
    </ng-template>
  </div>
</main>
