import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ApiService} from "../../../../core/services/api.service";
import {OrganizationService} from "../../../../core/services/organization.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ToolbarService} from "../../../../core/services/toolbar.service";
import {RoleModel} from "../../../../core/models/RoleModel";

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss']
})
export class UserRolesComponent implements OnInit, OnDestroy {

  isLoading = false;

  isEventManager = false;

  roles: RoleModel[] = [];

  constructor(private httpClient: HttpClient, private apiService: ApiService,
              private organizationService: OrganizationService, private router: Router, private route: ActivatedRoute,
              private toolbarService: ToolbarService) {
    this.isEventManager = organizationService.getSelectedOrganization()?.is_event_manager ?? false;
  }

  ngOnInit(): void {
    this.toolbarService.setHierarchy([{title: "Konfiguration", path: "/home/settings"}]);
    this.toolbarService.setPageTitle("Benutzerrollen");

    this.loadData();
  }

  ngOnDestroy(): void {
    this.toolbarService.resetHierarchy();
  }

  private loadData(): void {
    this.isLoading = true;

    let organizationId = `${this.organizationService.getSelectedOrganization()?.id ?? 0}`;

    this.apiService.settingsGetRoles(organizationId, (roles: RoleModel[]) => {
      this.roles = roles.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
    }, (error: string) => {
      // todo
    }, () => {
      this.isLoading = false;
    })
  }

  open(id: string): void {
    this.router.navigate([id], {relativeTo: this.route});
  }

}
