import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {OrganizationService} from "../../../../../core/services/organization.service";
import {ApiService} from "../../../../../core/services/api.service";
import {MatDialog} from "@angular/material/dialog";
import {ToolbarService} from "../../../../../core/services/toolbar.service";
import {AlertComponent} from "../../../../../dialogs/alert/alert.component";
import {PermissionModel} from "../../../../../core/models/PermissionModel";
import {SettingsPostRolesRequest} from "../../../../../core/network/request/SettingsPostRolesRequest";

@Component({
  selector: 'app-create-edit-user-role',
  templateUrl: './create-edit-user-role.component.html',
  styleUrls: ['./create-edit-user-role.component.scss']
})
export class CreateEditUserRoleComponent implements OnInit, OnDestroy {
  isLoading = false;
  isLoadingPermissions = false;
  isSaving = false;

  permissions: PermissionModel[] = [];

  roleId: string | null = null;
  roleName = '';
  roleDescription = '';

  constructor(private router: Router, private organizationService: OrganizationService, private apiService: ApiService,
              public dialog: MatDialog, private toolbarService: ToolbarService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      this.roleId = paramMap.get('id');
      if ((this.roleId ?? '') != '') {
        // todo: load role // this.loadData(this.roleId!!);
        this.loadToolbar();
      }
    });

    this.loadToolbar();
    this.loadData();
  }

  ngOnDestroy(): void {
    this.toolbarService.resetHierarchy();
  }

  private loadToolbar() {
    this.toolbarService.setHierarchy([
      {title: "Konfiguration", path: "/home/settings"},
      {title: "Benutzerrollen", path: "/home/settings/roles"}
    ]);
    this.toolbarService.setPageTitle("Benutzerrolle " + (this.roleId ? 'bearbeiten' : 'erstellen'));
  }

  onSaveClick(): void {
    console.log('create');
    this.isSaving = true;

    let permissionKeys = this.permissions
      .filter(x => x.isChecked)
      .map(x => x.key);

    let request: SettingsPostRolesRequest = {
      organizationId: `${this.organizationService.getSelectedOrganization()?.id ?? 0}`,
      label: this.roleName,
      description: this.roleDescription,
      permissionKeys: permissionKeys
    };

    this.apiService.settingsPostRoles(request, () => {
      this.router.navigateByUrl('/home/settings/roles');
    }, error => {
      switch (error) {
        default:
          this.showAlert("Unbekannter Fehler", "Unbekannter Fehler beim Erstellen des Ereignisses! Bitte versuche es später nochmal.");
      }
      this.isSaving = false;
    }, () => {
      this.isSaving = false;
    })
  }

  private showAlert(title: string, text: string, afterClosed: (() => any) | null = null) {
    this.dialog.open(AlertComponent, {
      data: {
        title: title,
        text: text
      },
      disableClose: true
    }).afterClosed().subscribe(() => {
      if (afterClosed) afterClosed();
    });
  }

  private loadData(): void {
    this.isLoadingPermissions = true;

    this.apiService.settingsPermissions(permissions => {
      this.permissions = permissions.sort((a, b) => {
        return a.label > b.label ? 1 : -1;
      });
      this.isLoadingPermissions = false;
    }, error => {
      // todo: handle
      this.isLoadingPermissions = false;
    }, () => {
    })
  }
}
