<main *ngIf="operationWaterSource">
  <div id="name">{{ operationWaterSource.name }}</div>
  <div id="subtitle">
    {{ operationWaterSource.sourceTypeName }} (#{{ operationWaterSource.idForUser }})
  </div>

  <table *ngIf="operationWaterSource.connections || operationWaterSource.capacity || operationWaterSource.flowrate
   || operationWaterSource.nominalDiameter">
    <tbody>
    <tr *ngIf="operationWaterSource.connections">
      <td>Anschlüsse</td>
      <td>{{ operationWaterSource.connections }}</td>
    </tr>
    <tr *ngIf="operationWaterSource.capacity">
      <td>Kapazität</td>
      <td>{{ operationWaterSource.capacity }} m³</td>
    </tr>
    <tr *ngIf="operationWaterSource.flowrate">
      <td>Zufluss</td>
      <td>{{ operationWaterSource.flowrate }} l/min</td>
    </tr>
    <tr *ngIf="operationWaterSource.nominalDiameter">
      <td>Nenn-⌀</td>
      <td>{{ operationWaterSource.nominalDiameter }} mm</td>
    </tr>
    </tbody>
  </table>
  <table>
    <tbody>
    <tr *ngIf="operationWaterSource.address">
      <td>Adresse</td>
      <td>{{ operationWaterSource.address }}</td>
    </tr>
    <tr *ngIf="operationWaterSource.distanceInMeters">
      <td>Entfernung</td>
      <td>{{ operationWaterSource.distanceInMeters | number: '1.2-2' }} m</td>
    </tr>
    <tr>
      <td>Koordinaten</td>
      <td>Breite: {{ operationWaterSource.lat }}<br>Länge: {{ operationWaterSource.lng }}</td>
    </tr>
    </tbody>
  </table>
  <table *ngIf="operationWaterSource.driveway || operationWaterSource.notes">
    <tbody>
    <tr *ngIf="operationWaterSource.driveway">
      <td>Zufahrt</td>
      <td>{{ operationWaterSource.driveway }}</td>
    </tr>
    <tr *ngIf="operationWaterSource.notes">
      <td>Anmerkungen</td>
      <td>{{ operationWaterSource.notes }}</td>
    </tr>
    </tbody>
  </table>
  <div id="link" *ngIf="operationWaterSource.wasserkartePortalUrl">
    <a (click)="onLinkClick($event)" target="_blank">In wasserkarte.info öffnen</a>
  </div>
</main>
